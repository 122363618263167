<template>
  <div>
    <ed-empresa :intTabelaPrecoId="0"  :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdEmpresa from "@/components/cadastro/tabelaPreco/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdEmpresa },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>